body{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
img{max-width: 100%;}
.container{max-width:1400px;}
h2{
    font-size: 24px; color:#1C1C1C; text-transform: uppercase; font-weight: 600;
}
h3{font-size: 18px; color:#1C1C1C; font-weight: 600;}
h4{font-size: 16px; color:#1C1C1C; font-weight: 600;}
p{font-size: 14px; color:#4B4B4B; font-weight:500; line-height:25px;}



.footer {
    background-color: #f0f0f0;
    color: #333;
    padding: 20px;
  }
 .btn{background-color: #198754; border:1px solid #198754}
 .btn:hover{background-color: #198754; border:1px solid #198754}

 .form-control, .form-select{
width: 100%;
height: 45px;
font-weight: 500;
font-size: 14px;
background-color: #eeeeee;
border: none;
outline: none;
}
.form-control:focus{box-shadow:none;    background-color: #eeeeee;}
.form-label{
    margin: 0;
}
.textarea{
    height: 200px;
    padding: 20px 20px;
    font-size: 18px;
    font-weight: 500;
}
p.text-post {
padding-top: 10px;
}



  
  
  
 